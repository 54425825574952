<template>
  <div class="min-h-[120px] w-full rounded-xl p-6 drop-shadow-sm" :class="bgColor">
    <GoogleReviewHeader v-if="isGoogleType" :review="review"></GoogleReviewHeader>
    <SurveyReviewHeader v-else-if="isSurveyReviewType" :review="review"></SurveyReviewHeader>
    <ReviewHeader v-else :review="review" :statuses="statuses" :show-status="showActions" :patient-path="patientPath"></ReviewHeader>
    <div class="review-card-body">
      <div v-if="review.content" class="review-card-body-content mb-3">
        <p>{{ review.content }}</p>
      </div>
      <div v-else class="empty-placeholder text-xs text-textLight" :class="review.reply ? '' : 'mb-4'"></div>
      <div v-if="showActions" class="flex items-center justify-start">
        <div v-if="!review.reply && showRespondToReview" class="review_card-body-add-response mr-2">
          <button
            class="group flex items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-primary hover:text-primary"
            @click="showReviewAddResponseModal = true"
          >
            <hero-icon name="CreateIonIcon" classes="mr-1" size="small"></hero-icon>
            <div class="text-left text-xs">{{ $t('reviews.respond') }}</div>
          </button>
        </div>
        <div v-if="!isGoogleType && !review.comment" classes="mr-2 review_card-body-add-comment">
          <button
            class="group flex items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-primary hover:text-primary"
            @click="showReviewAddCommentModal = true"
          >
            <hero-icon name="CreateIonIcon" classes="mr-1" size="small"></hero-icon>
            <div class="text-left text-xs">{{ $t('reviews.comment') }}</div>
          </button>
        </div>
        <Modal
          v-model="showReviewAddResponseModal"
          :title="$t('reviews.add_response.modal.title')"
          wrapper-class="modal-wrapper"
          modal-class="modal modal-lg"
        >
          <ReviewResponseModal
            :review="review"
            :clinic-id="clinicId"
            :ia-alert-message="iaAlertMessage"
            :ia-feature-active="iaFeatureActive"
            :templates="templates"
            :modal-type="type"
            :patient-path="patientPath"
            @close="showReviewAddResponseModal = false"
          />
        </Modal>
        <Modal
          v-if="!isGoogleType"
          v-model="showReviewAddCommentModal"
          :title="$t('reviews.add_comment.modal.title')"
          wrapper-class="modal-wrapper"
          modal-class="modal modal-lg"
        >
          <ReviewCommentModal :modal-type="type" :review="review" :patient-path="patientPath" @close="showReviewAddCommentModal = false" />
        </Modal>
        <div v-if="review.surveyResultPath" classes="mr-2 review_card-body-add-comment">
          <a
            :href="review.surveyResultPath"
            class="group flex items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-primary hover:text-primary hover:no-underline"
          >
            <hero-icon name="DocumentOutlineIonIcon" classes="mr-1" size="small"></hero-icon>
            <div class="text-left text-xs">{{ $t('reviews.survey_result') }}</div>
          </a>
        </div>
        <button
          v-if="showConnectorButton"
          class="flex items-center justify-center rounded-full border border-transparent px-2 py-1 text-left text-xs"
          :class="disableConnectorButtonClasses"
          @click="handleSendToConnector"
        >
          <hero-icon
            v-show="!errorMessage"
            :name="disableConnectorButton ? 'CheckHeroIcon' : 'PaperPlaneIonIcon'"
            classes="mr-1"
            size="small"
          ></hero-icon>
          <div v-if="errorMessage">
            <span class="mr-2 text-xs text-danger">{{ errorMessage }}</span>
          </div>
          <div v-else class="text-xs">{{ disableConnectorButton ? connectorSentLabel : connectorButtonLabel }}</div>
        </button>
      </div>
      <div v-if="replyExists" class="review-card-body-response ml-4 border-s-2 border-primary px-2 py-1" :class="review.content ? 'mt-6' : 'mt-2'">
        <div class="review-card-body-response-message mb-4">
          <div class="mb-3 flex items-center">
            <p class="mr-4 text-xs text-textSecondary">{{ $t('reviews.response_of_establishment') }}</p>
            <p class="text-xs text-textLight">{{ review.replyDisplayDate }}</p>
            <p class="ml-2 text-xs text-textLight">{{ review.responder }}</p>
          </div>
          <p class="pl-2 text-sm text-textPrimary">{{ review.reply }}</p>
        </div>
        <div v-if="showActions && type === 'google'" class="review-card-body-response-actions flex w-full items-center justify-start">
          <button
            class="group mr-1 flex items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-primary hover:text-primary"
            @click="showReviewAddResponseModal = true"
          >
            <hero-icon name="PencilHeroIcon" classes="mr-1 h-3"></hero-icon>
            <span class="text-xs text-gray-400 group-hover:text-primary">{{ $t('reviews.reply.edit') }}</span>
          </button>
          <button
            class="group flex items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-primary hover:text-primary"
            :disabled="showDeleteReplyMessage"
            :class="showDeleteReplyMessage ? 'hover:border-transparent' : ''"
            @click="showDeleteGoogleReviewModal = true"
          >
            <div v-if="showDeleteReplyMessage">
              <span v-if="deleteReplyErrorMessage" class="mr-2 text-xs text-danger">{{ deleteReplyErrorMessage }}</span>
              <span v-else class="mr-2 text-xs text-success">{{ $t('reviews.reply.delete_success_message') }}</span>
            </div>
            <div v-else class="flex items-center">
              <hero-icon name="TrashHeroIconHeroIconHeroIconHeroIcon" classes="mr-1 h-3"></hero-icon>
              <span class="text-xs text-gray-400 group-hover:text-primary">{{ $t('reviews.reply.delete') }}</span>
            </div>
          </button>
          <Modal
            v-model="showDeleteGoogleReviewModal"
            :title="$t('confirmation.modal.title')"
            wrapper-class="modal-wrapper"
            modal-class="modal modal-md"
          >
            <ConfirmationModal
              :message="$t('reviews.delete_review.modal.message')"
              @confirmed="handleDeleteReply"
              @close="showDeleteGoogleReviewModal = false"
            />
          </Modal>
        </div>
      </div>
      <div v-if="commentExists" class="review-card-body-response ml-4 border-s-2 border-yellow px-2 py-1" :class="review.comment ? 'mt-6' : 'mt-2'">
        <div class="review-card-body-response-message mb-4">
          <div class="mb-3 flex items-center">
            <p class="mr-4 text-xs text-textSecondary">{{ $t('reviews.internal_comment') }}</p>
            <p class="text-xs text-textLight">{{ review.commentDisplayDate }}</p>
            <p class="ml-2 text-xs text-textLight">{{ review.commentator }}</p>
          </div>
          <p class="pl-2 text-sm text-textPrimary">{{ review.comment }}</p>
        </div>
        <div v-if="showActions" class="review-card-body-response-actions flex w-full items-center justify-start">
          <button
            class="group mr-1 flex items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-primary hover:text-primary"
            @click="showReviewAddCommentModal = true"
          >
            <hero-icon name="PencilHeroIcon" classes="mr-1 h-3"></hero-icon>
            <span class="text-xs text-gray-400 group-hover:text-primary">{{ $t('reviews.comments.edit') }}</span>
          </button>
          <button
            class="group flex items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-primary hover:text-primary"
            :disabled="showDeleteComment"
            :class="showDeleteComment ? 'hover:border-transparent' : ''"
            @click="showDeleteCommentModal = true"
          >
            <div v-if="showDeleteComment">
              <span v-if="deleteCommentErrorMessage" class="mr-2 text-xs text-danger">{{ deleteCommentErrorMessage }}</span>
              <span v-else class="mr-2 text-xs text-success">{{ $t('reviews.comments.delete_success_message') }}</span>
            </div>
            <div v-else class="flex items-center">
              <hero-icon name="TrashHeroIcon" classes="mr-1 h-3"></hero-icon>
              <span class="text-xs text-gray-400 group-hover:text-primary">{{ $t('reviews.comments.delete') }}</span>
            </div>
          </button>
          <Modal v-model="showDeleteCommentModal" :title="$t('confirmation.modal.title')" wrapper-class="modal-wrapper" modal-class="modal modal-md">
            <ConfirmationModal
              :message="$t('reviews.delete_comment.modal.message')"
              @confirmed="handleDeleteReviewComment"
              @close="showDeleteCommentModal = false"
            />
          </Modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import ReviewResponseModal from '@components/shared/modals/ReviewResponseModal.vue';
import ReviewHeader from '@components/shared/cards/ReviewHeader.vue';
import SurveyReviewHeader from '@components/shared/cards/SurveyReviewHeader.vue';
import GoogleReviewHeader from '@components/shared/cards/GoogleReviewHeader.vue';
import ReviewCommentModal from '@components/shared/modals/ReviewCommentModal.vue';
import ConfirmationModal from '@components/shared/modals/ConfirmationModal.vue';
import { submitSendReviewToConnector, deleteGoogleReviewResponse, updateReviewComment, updateSurveyReviewComment } from '@services/reviewService';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  // @example:
  // { id:, author:, content:, comment:, avatarURl:, totalRatingNb:, rating:, reviewDisplayDate:, replyDisplayDate:, reply:}
  review: {
    type: Object,
    required: true,
  },
  bgColor: {
    type: String,
    required: false,
    default: 'bg-white',
  },
  type: {
    type: String,
    default: 'google', // also 'review', 'surveyReview'
  },
  clinicId: {
    type: Number,
    default: null,
  },
  sendReviewsToConnectorManually: {
    type: Boolean,
    default: false,
  },
  connectorName: {
    type: String,
    default: 'votre application',
  },
  showActions: {
    type: Boolean,
    default: true,
  },
  showRespondToReview: {
    type: Boolean,
    required: false,
    default: true,
  },
  iaAlertMessage: {
    type: String,
    required: false,
    default: '',
  },
  iaFeatureActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  templates: {
    type: Array,
    default: () => {
      return [];
    },
  },
  statuses: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  patientPath: {
    type: String,
    required: false,
    default: 'javascript:void(0);',
  },
});

const showReviewAddResponseModal = ref(false);
const showReviewAddCommentModal = ref(false);
const showDeleteGoogleReviewModal = ref(false);
const showDeleteCommentModal = ref(false);
const connectorButtonClickedAt = ref(null);
const errorMessage = ref(null);
const deleteReplySuccessMessage = ref(null);
const deleteReplyErrorMessage = ref(null);
const deleteCommentSuccessMessage = ref(null);
const deleteCommentErrorMessage = ref(null);

watch(showReviewAddResponseModal, () => {
  if (showReviewAddResponseModal.value) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
});

// const availableStatuses = computed(() => {
//   return props.statuses || [t('reviews.status.new'), t('reviews.status.in_process'), t('reviews.status.closed')];
// });

const showDeleteReplyMessage = computed(() => {
  return deleteReplySuccessMessage.value !== null || deleteReplyErrorMessage.value !== null;
});

const isGoogleType = computed(() => {
  return props.type === 'google';
});

const isSurveyReviewType = computed(() => {
  return props.type === 'surveyReview';
});

const showDeleteComment = computed(() => {
  return deleteCommentSuccessMessage.value !== null || deleteCommentErrorMessage.value !== null;
});

const replyExists = computed(() => {
  return props.review && props.review.reply && props.review.reply.length > 0;
});

const commentExists = computed(() => {
  return props.review && props.review.comment && props.review.comment.length > 0;
});

const connectorButtonLabel = computed(() => {
  return t('reviews.connector.button', { connector: props.connectorName });
});

const connectorSentLabel = computed(() => {
  const dateString = connectorButtonClickedAt.value || props.review.authorizedForSyncWithConnectorAt;
  const dateTime = new Date(dateString);
  const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
  return t('reviews.connector.sent_label', { connector: props.connectorName, date: formattedDate });
});

const showConnectorButton = computed(() => {
  return props.review.content && props.sendReviewsToConnectorManually;
});

const disableConnectorButton = computed(() => {
  return props.review.authorizedForSyncWithConnectorAt != null || connectorButtonClickedAt.value != null;
});

const disableConnectorButtonClasses = computed(() => {
  if (disableConnectorButton.value) {
    return 'leading-normal hover:border-transparent  bg-blue-100 text-blue-500 cursor-default';
  } else {
    return 'hover:border-primary hover:text-primary group bg-transparent text-gray-400';
  }
});

const handleSendToConnector = async () => {
  try {
    const res = await submitSendReviewToConnector({ reviewId: props.review.id, clinicId: props.clinicId, type: props.type });
    if (res.data.success) {
      connectorButtonClickedAt.value = new Date();
    }
  } catch (error) {
    errorMessage.value =
      (error.response && error.response.data && error.response.data.errors.join('.\n')) || error.message || t('errors.default_message');
    setTimeout(function () {
      errorMessage.value = null;
    }, 2000);
  }
};

const handleDeleteReply = async () => {
  try {
    showDeleteGoogleReviewModal.value = false;
    await deleteGoogleReviewResponse({ reviewId: props.review.id, clinicId: props.clinicId });
    deleteReplySuccessMessage.value = true;
    setTimeout(function () {
      deleteReplySuccessMessage.value = null;
      window.location.reload();
    }, 2000);
  } catch (error) {
    deleteReplyErrorMessage.value =
      (error.response && error.response.data && error.response.data.errors.join('.\n')) || error.message || t('errors.default_message');
    setTimeout(function () {
      deleteReplyErrorMessage.value = null;
    }, 2000);
  }
};

const handleDeleteReviewComment = async () => {
  try {
    showDeleteCommentModal.value = false;
    switch (props.type) {
      case 'review':
        await updateReviewComment(props.review.id, { comment: '' });
        break;
      case 'surveyReview':
        await updateSurveyReviewComment(props.review.id, { comment: '' });
        break;
    }
    deleteCommentSuccessMessage.value = true;
    setTimeout(function () {
      deleteCommentSuccessMessage.value = null;
      window.location.reload();
    }, 2000);
  } catch (error) {
    deleteCommentErrorMessage.value =
      (error.response && error.response.data && error.response.data.errors.join('.\n')) || error.message || t('errors.default_message');
    setTimeout(function () {
      deleteCommentErrorMessage.value = null;
    }, 2000);
  }
};
</script>
